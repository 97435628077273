import React, {useEffect, useState} from 'react';
import {Col, Form, Modal} from "react-bootstrap";
import DocumentsForm from "../../documents/components/documentsForm";
import Loader from "../../../components/loader";
import _ from "lodash";
import SearchSelect from "../../../components/searchSelect";
import {Controller, useForm} from "react-hook-form";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import LoadingButton from "../../base/components/loadingButton";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import BlockUi from "@availity/block-ui";


const types = [
  {value:'avia', label:'Авиа'},
  {value:'railway', label:'ЖД'},
  {value:'service', label:'Услуги'},
]

const SupSuplierModal = ({id,counterpartyData, children, reload}) => {
  const supSupTempData = {
    name:'',
    types:['avia'],
    subSupplierId: "",
    agreementNumber: "",
    dontExport:false,
    amadeusCode:counterpartyData.amadeusCode
  }
  const [show, setShow] = useState(false);
  const [data, setData] = useState()
  const [loading, setLoading] = useState(!!id)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if(id && show){
      axios.get('/api/counterparty/suppliers/'+id)
        .then(({data})=>{
          setData(data.supplier)
        })
        .catch(sayError)
        .finally(()=>setLoading(false))
    }else{
      setData(supSupTempData)
    }
    if(!show){
      setData()
    }
  }, [show]);
  const {
    formState:{errors},
    register,
    control,
    handleSubmit,
    getValues
  } = useForm(data)
  const afterSubm = () =>{
    reload()
    handleClose()
  }
  const onSubmit = (data) =>{
    console.log(data)
    setLoading(true)
    if(id){
      axios.patch('/api/counterparty/suppliers/'+id,data)
        .then(afterSubm).catch(sayError).finally(()=>setLoading(false))
    }else {
      axios.put('/api/counterparty/suppliers',{...data,counterparty:counterpartyData.counterparty})
        .then(afterSubm).catch(sayError).finally(()=>setLoading(false))
    }
  }
  const deleteSupSup = () =>{
    setLoading(true)
    if(window.confirm('Вы уверены, что хотите удалить '+ data.name+'?')){
      axios.delete('/api/counterparty/suppliers/'+id)
        .then(afterSubm).catch(sayError).finally(()=>setLoading(false))
    }
  }
  return (
    <>
      <div onClick={handleShow}>{children}</div>
      <Modal show={show}
             // dialogClassName="modal-90w"
             onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Информация субпоставщика</Modal.Title>
        </Modal.Header>
        <BlockUi blocking={loading}>
          <Modal.Body >
            {data && <form onSubmit={handleSubmit(onSubmit)} className={'d-flex flex-column gap-3'}>
              <FormHookInput
                defaultValue={data.name}
                label={'Имя'}
                register={register(`name`)}
              />

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={data.types}
                render={({field: {onChange, onBlur, value}}) => (
                  <>
                    <SearchSelect
                      onChange={(v) => {
                        onChange(v)
                        console.log('itog', v)
                      }}
                      defaultValue={value}
                      isMulti={true}
                      hasError={_.get(errors, 'types')}
                      style={{minWidth: 250}}
                      label={'Контрагент'}
                      array={types}/>
                  </>

                )}
                name={`types`}
              />
              <FormHookInput
                defaultValue={data.amadeusCode}
                label={'Код Amadeus'}
                register={register(`amadeusCode`)}
              />
              <FormHookInput
                defaultValue={data.subSupplierId}
                type={'number'}
                label={'Код поставщика в 1с Аруаны'}
                register={register(`subSupplierId`,{maxLength:9})}
              />
              <FormHookInput
                defaultValue={data.agreementNumber}
                type={'number'}
                label={'Код договора(только для услуг)'}
                register={register(`agreementNumber`,{maxLength:9})}
              />
              <Form.Label className={'d-flex align-items-center gap-2'}>
                <input
                  defaultChecked={data.dontExport}
                  {...register(`dontExport`)}
                  type={"checkbox"}
                />{" "}
                Не экспортировать
              </Form.Label>
            </form>}

          </Modal.Body>
          <Modal.Footer>
            {id && <LoadingButton title={'Удалить'}
                                  variant={'outline-danger'}
                                  onClick={deleteSupSup}/>}
            <LoadingButton title={'Сохранить'} onClick={handleSubmit(onSubmit)}/>
          </Modal.Footer>
        </BlockUi>
      </Modal>


    </>
  );
};

export default SupSuplierModal;