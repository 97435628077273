import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { SearchInput, StartProcess } from "../../../../redux/actions/base";
import { connect } from "react-redux";

const ApiSelect = props => {
  const { cb, url, value, className, placeholder, isMulti, hasError, queryBase,label, style } = props;
  const st = style ? {position: 'relative',...style}: {position: 'relative'}
  const [selectValue, setSelectValue] = useState(null);
  const [failed, setFailed] = useState(false);
  const [searching, setSearching] = useState(false);
  useEffect(() => {
    console.log('url', url)
    console.log("ApiSelect use", selectValue, searching);
    if (!selectValue ) {
      console.log("trigger", value);
      setSearching(true);
      if (isMulti && value.length) {
        props.SearchInput(
          url,
          res => {
            setSearching(false);
            setSelectValue(res.data.data);
          },
          { ids: value }
        );
        // console.log("selectedvalue----1", value);
      } else if (!isMulti && value) {
        const sendOptions = queryBase ? {...queryBase, id: value} : {id: value}
        // console.log('oneValue')
        props.SearchInput(
          url,
          res => {
            setSearching(false);
            if (!res.data.data || !res.data.data[0]) {
              setFailed(true);
            } else {
              // console.log("test", res.data);
              setSelectValue(res.data.data[0]);
            }
          },
          sendOptions
        );
        // console.log("selectedvalue----2", value);
      }
    }
    if (!value) {
      console.log(123);
      setSelectValue(null);
    }
  }, [value]);
  useEffect(() => {
    console.log('22222222')
  }, [value]);

  const loadOptions = (param1, showResults) => {
    if (param1.length > 2) {
      // console.log("SearchInput");
      const sendOptions = queryBase ? {
        ...queryBase, query: param1
        } : {query: param1}
      props.SearchInput(url, res => showResults(res.data.data), sendOptions);
    }
  };

  const setSelectValueForm = option => {
    console.log("option", option);
    if (isMulti) {
      cb(option.map(o => o.value));
    } else {
      cb(option.value);
    }
    setSelectValue(option);
  };

  return (
    <div style={st}>
      <label className={'absolutLabel'}>{label}</label>

      <AsyncSelect
        isMulti={isMulti}
        placeholder={placeholder ? placeholder : "Select..."}
        className={className}
        value={selectValue}
        styles={
          hasError
            ? {
              control: provided => {
                return {...provided, borderColor: "#fd397a"};
              },
              menu:provided => {
                return {...provided, zIndex: '10000'};
              },
            }
            : {
              menu:provided => {
                return {...provided, zIndex: '10000'};
              },
            }
        }
        onChange={setSelectValueForm}
        loadOptions={loadOptions}
      />
    </div>

  );
};

export default connect(null, {SearchInput })(ApiSelect);
