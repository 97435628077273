import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletFooter, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {useController, useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import ApiSelect from "../../base/components/forms/apiSelect";
import _ from "lodash";
import IssuedBySelect from "../../air/airBooking/components/issuedBySelect";
import ServicesFields from "./ServicesFields";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import LoadingButton from "../../base/components/loadingButton";
import axios from "../../../helpers/axios";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import PaidButton from "./PaidButton";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link, useParams} from "react-router-dom";
import {TouristServicesListRoute} from "../../routesConstants";
import sayError from "../../../helpers/sayError";
import SearchSelect from "../../../components/searchSelect";
import PotentialBuyers from "./potentialBuyers";

const TouristServicesForm = ({data, onSubmit, edit, removeOrder, potentialBuyers, fromParser}) => {
  const {id} = useParams();
  const formController = useForm({shouldUnregister: true})
  const [types, setTypes] = useState()
  const [emplArray, setEmplArray] = useState()
  useEffect(() => {
    axios.post('/api/touristServices/typesSearch')
      .then(({data})=>{
        console.log('types', data)
        setTypes(data.data)
      })
      .catch(sayError)
    axios.post('/api/users/emplSearch', {operatorOneS: true})
      .then(({data})=>{
        const newArr = data.data.map(user=>{
          return {label: user.label, value: user.value._id}
        })
        setEmplArray(newArr)
      })
      .catch(sayError)
  }, []);

  const {control,
    register,
    getValues,
    handleSubmit,
  formState:{errors}} = formController
  const {
    field: { onChange: sellerOnChange, value: sellerValue }
  } = useController({
    name: `seller`,
    control,
    rules: { required: true },
    defaultValue: data.seller
  });
  const {
    field: { onChange: buyerOnChange, value: buyerValue }
  } = useController({
    name: `buyer`,
    control,
    rules: { required: true },
    defaultValue: data.buyer
  });
  const {
    field: { onChange: issuedByOnChange, value: issuedByValue }
  } = useController({
    name: `issuedBy`,
    control,
    rules: { required: true },
    defaultValue: data.issuedBy
  });
  console.log('values', getValues(), errors)
  const setBuyer = (id) =>{
    buyerOnChange(id)
  }

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumb" style={{paddingBottom: 15}}>
        <Link to={TouristServicesListRoute}>Список услуг</Link>
        <Typography color="textPrimary">Услуга</Typography>
      </Breadcrumbs>
      <Row>
        <Col xs={9}>
          <Portlet>
            <PortletHeader title={'Загрузить туристическую услугу'}
                           toolbar={edit && data.issuedWithoutDocs &&<PortletHeaderToolbar >
                             <PaidButton  paid={data.paid} id={id}/>
            </PortletHeaderToolbar>}/>
            <form onSubmit={handleSubmit(onSubmit)}>
              <PortletBody>
                <div className={'mb-3'}>При сохранении услуги <b>дата выписки</b> автоматически поменяется на <b>дату окончания услуг</b></div>
                <Row className={'mb-3'}>
                  <Col xs="3">
                    <ApiSelect
                      value={sellerValue}
                      label={'Продавец'}
                      hasError={_.get(errors, `seller`)}
                      url={"/api/counterparty/searchInput"}
                      cb={newVal => sellerOnChange(newVal)}
                    />
                  </Col>
                  <Col xs="3">
                    <ApiSelect
                      value={buyerValue}
                      label={'Покупатель'}
                      hasError={_.get(errors, `buyer`)}
                      url={"/api/counterparty/searchInput"}
                      cb={newVal => {
                        buyerOnChange(newVal);
                      }}
                    />
                  </Col>
                  <Col xs="3">
                    <CustomDatePicker formController={formController}
                                      label={"Дата выписки"}
                                      value={data.issueDate}
                                      name={`issueDate`}
                                      rules={{required: true}}/>
                  </Col>
                  <Col xs="3">
                    {emplArray && <SearchSelect
                      onChange={(v) => {
                        issuedByOnChange(v)
                      }}
                      defaultValue={data.issuedBy}
                      hasError={_.get(errors, "issuedBy")}
                      label={'Выписал'}
                      array={emplArray}/>}
                  </Col>

                </Row>
                <Row className={'mb-3'}>
                  <Col>
                    <FormHookSelect
                      defaultValue={data.tourismType}
                      register={register(`tourismType`, {required: true})}
                      errors={errors}
                      label={'Вид туризма'}
                    >
                      {/*<option value="">Выбрать вид</option>*/}
                      <option value={'Выездной'}>{'Выездной'}</option>
                      <option value={'Въездной'}>{'Въездной'}</option>
                      <option value={'Внутренний'}>{'Внутренний'}</option>
                    </FormHookSelect>
                  </Col>
                  <Col>
                    <FormHookSelect
                      defaultValue={data.purpose}
                      register={register(`purpose`, {required: true})}
                      errors={errors}
                      label={'Цель поездки'}
                    >
                      {/*<option value="">Выбрать цель</option>*/}
                      <option value={'Туризм'}>{'Туризм'}</option>
                      <option value={'Деловая'}>{'Деловая'}</option>
                      <option value={'Частная'}>{'Частная'}</option>
                    </FormHookSelect>
                  </Col>
                  <Col>
                    <FormHookSelect
                      defaultValue={data.docState}
                      register={register(`docState`, {required: true})}
                      errors={errors}
                      label={'Статус документа'}
                    >
                      {/*<option value="">Выбрать статус</option>*/}
                      <option value={'Предварительный'}>{'Предварительный'}</option>
                      <option value={'Окончательный'}>{'Окончательный'}</option>
                    </FormHookSelect>
                  </Col>
                  <Col>
                    <Form.Label className={'d-flex align-items-center gap-2'}>
                      <input
                        defaultChecked={data.issuedWithoutDocs}
                        {...register(`issuedWithoutDocs`)}
                        type={"checkbox"}
                      />{" "}
                      Без документов
                    </Form.Label>
                    <Form.Label className={'d-flex align-items-center gap-2'}>
                      <input
                        defaultChecked={data.public}
                        {...register(`public`)}
                        type={"checkbox"}
                      />{" "}
                      Публичный
                    </Form.Label>
                  </Col>
                </Row>
                <Row className={'mb-3'}>
                  <Col>
                    <FormHookInput
                      register={register(`message`)}
                      label={'Комментарий 1с'}
                      errors={errors}
                    />
                  </Col>

                </Row>
                <div className={'mb-2'}>Если выбрано <b>"Без документов"/"Website"</b>, то эти услуги официально не
                  проводятся. Оплата только на <b>ЛИЧНЫЕ карты.</b>
                  <br/>
                  <b>Не забудьте предупредить клиента!</b></div>

                <ServicesFields formController={formController}
                                edit={edit}
                                fromParser={fromParser}
                                types={types}
                                services={data.serviceItems}/>

              </PortletBody>
              <PortletFooter>
                <LoadingButton title={"Сохранить"} type={'submit'} loading={false}/>
              </PortletFooter>
            </form>


          </Portlet>
        </Col>
        <Col xs={3}>
          {potentialBuyers && <PotentialBuyers potentialBuyers={potentialBuyers}
                                               setBuyer={setBuyer}/> }
          <Portlet>
            <PortletHeader title={'Общая информация'}/>
            {getValues().serviceItems && <><PortletBody>
              <h6>Всего услуг: {getValues().serviceItems.length}</h6>
              {getValues().serviceItems.map((s,i)=>{
                const type = _.find(types, {id:s.type})?.name
                const sum =(parseInt(s.summKZT)+parseInt(s.commission)-parseInt(s.discount)+parseInt(s.krsKayar))*parseInt(s.quantity)
                console.log('type find', type)
                return <div key={i}>
                  <b>Услуга №{i + 1}<br/></b>
                  {s.mainPax || 'Выберите имя'} - {type || 'Выберите услугу'}<br/>
                  Сумма: {sum} KZT
                </div>
              })}
            </PortletBody>
              <PortletFooter>
                Общая стоимость: {_.sum(getValues().serviceItems.map((s,i)=>{
                const sum =(parseInt(s.summKZT)+parseInt(s.commission)-parseInt(s.discount)+parseInt(s.krsKayar))*parseInt(s.quantity)
                return +sum
              }))} KZT
              </PortletFooter>
            </>}
          </Portlet>
          {edit &&
            <Portlet>
              <PortletBody>
                <>Создано: <br/> Кем: {data.createdBy.name} <br/>Когда: {data.createDate} <br/></>
                {!data.issuedWithoutDocs? 'Оплата по документам' : data.paid ? <>Отмечено оплаченым: <br/> Кем: {data.markedPaidBy.name} <br/>Когда: {data.markedPaidAt} </>:<b>Не оплачено</b>}
              </PortletBody>
              <PortletFooter>
                <h5 className={'text-danger'}
                    onClick={removeOrder}
                    style={{cursor: 'pointer', textAlign: 'center'}}>
                  Удалить услуги <i className={"fa fa-trash"}/>
                </h5>
              </PortletFooter>
            </Portlet>
          }
        </Col>
      </Row>
    </>


  );
};

export default TouristServicesForm;