import React, {useState} from "react";
import { Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import numberFormat from "../../../helpers/numberFormat";
import moment from "moment";
import {showCount} from "../../../helpers/showCount";
import {renderRegisterName, targetWeek} from "./aruanaKayarReviseRender";
import ReloadReviseButton from "./reloadReviseButton";
import CopyDownloadLink from "./CopyDownloadLink";
import ReSaveReportButton from "./reSaveReportButton";
import _ from "lodash";
import ReviseDocumentWrapper from "./reviseDocumentWrapper";
import SilkKayarRender from "./SilkKayarRender";
import UpdateRevise from "./updateRevise";

const SilkKayarReviseRender = ({ revise }) => {
  const [data, setData] = useState(JSON.parse(revise.dataJson))
  const [canSend, setCanSend] = useState(false)
  const [sendFiles, setSendFiles] = useState(revise.documents.map((u, index) => {
    u.registerName = renderRegisterName(u.name)
    return u}))
  const file = _.find(revise.documents, {registerName: 'silkFile' })
  const options = {
    targetWeek: targetWeek(+moment(revise.startDate).format('D')),
    targetMonth: +moment(revise.startDate).format('M'),
    targetYear:+moment(revise.startDate).format('YYYY'),
    url: "/api/racoon/pdfParse/checkSilkKayar",
    reviseId:revise.id,
    file:file,
    oneCName: 'Каяр'
  }
  return (
    <>
      <ReviseDocumentWrapper revise={revise}>
        {sendFiles.map((u, index) => {
          return (
            <div key={index} className={'d-flex align-items-center mb-2 gap-2'}>
              {u.url ? <>
                <CopyDownloadLink url={u.url}/>
                <a download={u.url}
                   href={u.url}
                   target={"_blank"}
                   rel={'unsafe-url'}
                >
                  {u.name} ({u.originalName} )
                </a>
              </>: <>Загружен новый документ</>}
            </div>
          );
        })}
        <UpdateRevise cb={(data, file) => {
          setData(data)
          file ? setSendFiles([file]) : setSendFiles([])
          setCanSend(true)
        }}
                      options={options}/>
      </ReviseDocumentWrapper>
      <SilkKayarRender data={data}/>
    </>
  );
};
export default SilkKayarReviseRender;
