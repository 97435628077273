import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody} from "../../../components/Portlet";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import { Tab, Table, Tabs} from "react-bootstrap";
import _ from "lodash"
import {Link} from "react-router-dom";
import {TouristServicesCreateRoute, TouristServicesEditRoute} from "../../routesConstants";
import BlockUi from "@availity/block-ui";
import {useNavigate} from "react-router";
import Button from "react-bootstrap/Button";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import LoadingButton from "../../base/components/loadingButton";
import moment from "moment";
import {useForm} from "react-hook-form";
import SaveDocBtn from "../../touristServices/components/saveDocBtn";


const tabsName = {
  missedBookings:'Нет в базе',
  canceledBookings:'Отмененные',
  unpaidBookings:'Неоплаченные',
  correctBookins:'Правильные',
  canceledAndMissed:'Отмененные и ненайденные',
  unidentified:'Неопознаные'
}
const SinkRateHawk = () => {
  const start= moment().subtract(7,'d').format('DD.MM.YYYY')
  const end = moment().format('DD.MM.YYYY')
  const formController = useForm({
    start,end
  })
  const {handleSubmit,getValues } = formController
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false)
  const [table, setTable] = useState(null)
  const [firstLoad, setFirstLoad] = useState(true)
  const [addToInvoise, setAddToInvoise] = useState([])
  const navigate = useNavigate()
  const renderStatusIcon = (status)=>{
    if(status === 'completed') {
      return <i className="fa fa-check-square text-success" style={{marginRight:"0.5rem"}} />
    } else if (status === 'cancelled') {
      return <i className="fa fa-window-close text-danger" style={{marginRight:"0.5rem"}}/>
    } else {
      return <i className="fa fa-question text-warning" style={{marginRight:"0.5rem"}} />
    }
  }
  const takeTable = (period) => {
      console.log(period)
      setLoading(true)
      setDate(period)
      axios.post('/api/touristServices/ratehawk/revise',period)
        .then(({data})=>{
          console.log(data)
          setTable(data)
          setAddToInvoise([])
          document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
        })
        .catch(sayError)
        .finally(()=>setLoading(false))

  }
  const onFocus = () => {
    takeTable(getValues());
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    // Calls onFocus when the window first loads
    console.log('first')
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);


  const canseledInBase =(mongoId) =>{
    if(window.confirm('Вы уверены что хотите отменить?')){
      setLoading(true)
      axios.post("/api/touristServices/ratehawk/markCancelled",{mongoId})
        .then(()=>{
          takeTable(date)
        })
        .catch(sayError)
    }
  }
  const createTouristServ = () =>{
    const without = _.find(addToInvoise, (o)=>{return o.withoutDocs !== null})?.withoutDocs
    console.log(without)
    if(_.find(addToInvoise,(o)=>{return o.withoutDocs !==without && o.withoutDocs !== null})){
      window.alert('В одном тур-инвойсе могут быть услиги только с одним типом документов: "С документами" или "Без Документов"')
    }else{
      localStorage.setItem("items",  JSON.stringify(addToInvoise))
      window.open(TouristServicesCreateRoute,'_blank')
    }
  }



  return (
    <Portlet>
      <PortletBody>
        <form onSubmit={handleSubmit(takeTable)} className={'d-flex gap-2 align-items-center mb-2'}>
          <CustomDatePicker formController={formController}
                            label={"Дата от"}
                            value={start}
                            name={`start`}
                            rules={{required: true}}/>
          <CustomDatePicker formController={formController}
                            label={"Дата до"}
                            value={end}
                            name={`end`}
                            rules={{required: true}}/>
          <LoadingButton title={"Получить данные"}
                         disabled={loading}
                         loading={loading}/>
        </form>
        {table &&
          <BlockUi blocking={loading}>
            <Tabs defaultActiveKey="missedBookings">
              {Object.keys(table).map((key, i) => {
                return <Tab
                  eventKey={key}
                  key={i}
                  title={`${tabsName[key]} (${table[key].length})`}
                >
                  <Table striped bordered hover>
                    <thead>
                    <tr>
                      <th>Статус RH | Индивидуальный код</th>
                      <th>Создано</th>
                      <th>Отель</th>
                      <th>Период</th>
                      <th>Гость</th>
                      <th>Док.</th>
                      <th>О</th>
                      {(key === 'missedBookings' || key === 'canceledBookings' || key === 'unpaidBookings') &&
                        <th></th>}
                    </tr>
                    </thead>
                    <tbody>
                    {table[key].map((order, index) => {
                      return <tr key={index}>

                        <td>
                          <div className={'d-flex justify-content-between'}>
                            <div>
                              {(key === 'canceledBookings' || key === 'correctBookins') ?
                                <Link to={TouristServicesEditRoute + order.invoiceId}
                                      target={'_blank'}>{renderStatusIcon(order.status)}{order.orderId}</Link> :
                                <>{renderStatusIcon(order.status)}{order.orderId}</>}
                            </div>
                            {/*{key === 'unpaidBookings' &&*/}
                            {/*  <SaveDocBtn invoice={order.invoice} office={order.office}/> }*/}
                          </div>

                        </td>
                        <td>{order.createdAt}</td>
                        <td style={{textTransform: 'capitalize'}}>{order.hotelId.replace(/_/g, ' ')}</td>
                        <td>{order.dates}</td>
                        <td>{order.mainPax}</td>
                        <td>{order.withoutDocs === null ? <i className="fa fa-question"/> : !order.withoutDocs &&
                          <i className="fa fa-file-text" aria-hidden="true"/>}</td>
                        <td>{order.office === "B2B-209989" ? <i className="fa fa-font"/> :
                          <i className="fa fa-header"/>}</td>
                        {(key === 'missedBookings' || key === 'unpaidBookings') && <td>
                          <input id={'input_field'}
                                 defaultChecked={_.find(addToInvoise, {orderId: order.orderId})}
                                 onChange={(e) => {
                                   if (e.target.checked) {
                                     setAddToInvoise(other => ([...other, {
                                       orderId: order.orderId,
                                       hotelId: order.hotelId,
                                       office: order.office,
                                       withoutDocs: order.withoutDocs
                                     }]))
                                   } else {
                                     const newArr = _.filter(addToInvoise, (o) => {
                                       return o.orderId !== order.orderId
                                     })
                                     setAddToInvoise(newArr)
                                   }
                                 }}
                                 type={"checkbox"}
                          />{" "}

                        </td>}
                        {key === 'canceledBookings' && <td>
                          <div style={{cursor: 'pointer'}}
                               className={'text-danger d-flex align-items-center gap-2'}
                               onClick={() => {
                                 canseledInBase(order.mongoId)
                               }}>
                            <i className="fa fa-window-close"/>
                            <div>Отменить</div>
                          </div>

                        </td>}

                      </tr>

                    })}
                    {(key === 'missedBookings' || key === 'unpaidBookings') && <tr>
                      <td colSpan={7}>
                        <div className={'d-flex justify-content-end gap-2 w-100 align-items-center'}>
                          {/*<Link to={TouristServicesCreateRoute}*/}
                          {/*      state={addToInvoise}*/}
                          {/*      target={'_blank'}*/}
                          {/*      onClick={() => localStorage.setItem("items",  JSON.stringify(addToInvoise))}*/}
                          {/*      className={'btn bg-primary text-white'}>Создать тур-инвойс с выбранными услугами</Link>*/}
                          <Button variant={'primary'} onClick={createTouristServ}>
                            Создать тур-инвойс с выбранными услугами
                          </Button>
                        </div>
                      </td>
                    </tr>}
                    </tbody>
                  </Table>
                </Tab>
              })
              }
            </Tabs>
          </BlockUi>
        }

      </PortletBody>

    </Portlet>
  );
};

export default SinkRateHawk;