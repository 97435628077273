import React, {useState} from 'react';
import numberFormat from "../../../helpers/numberFormat";
import {Form, Row, Tab, Table, Tabs} from "react-bootstrap";
import {showCount} from "../../../helpers/showCount";
import moment from "moment/moment";

const YanaKayarRender = ({data}) => {
  const [showKrs, setShowKrs] = useState(false)
  return (
    <>
    <Row>
      <Tabs defaultActiveKey="correct">
        <Tab
          eventKey="correct"
          title={`Правильные (${data.correct.length})`}
        >
          <Table striped hover>
            {renderHeader()}
            {renderStandartBody(data.correct)}
          </Table>
        </Tab>
        <Tab
          eventKey="noYana"
          title={`Есть в Kayar но нет в Yana(Акт) (${showCount(data.yanaMissing)})`}
        >
          <Table striped hover>
            {renderHeader()}
            {renderStandartBody(data.yanaMissing)}
          </Table>
        </Tab>
        <Tab
          eventKey="noKayar"
          title={`Есть в Yana(Акт) но нет в Kayar (${showCount(data.kayarMissing)})`}
        >
          <Table striped hover>
            {renderHeader()}
            {renderStandartBody(data.kayarMissing)}
          </Table>
        </Tab>
        <Tab
          eventKey="diff"
          title={`Отличаются (${showCount(data.diff)})`}
        >
          <Table striped bordered hover>
            <thead>
            <tr>
              <td>Номер</td>
              <td>Выписан Yana</td>
              <td>Выписан Kayar</td>
              <td className={'text-center'}>Итог<br/>Yana/Kayar</td>
            </tr>
            </thead>
            <tbody>
            {data.diff.map((u, index) => {
              return (
                <tr key={index}>
                  <td>
                    {u.airline} {u.ticketNumber} {u.refund && ' B'}
                  </td>
                  <td>{moment(u.issueDate).format('DD.MM.YYYY')}</td>
                  <td>{moment(u.kayarIssueDate).format('DD.MM.YYYY')}</td>

                  <td>{renderValue(u.total)}</td>
                </tr>
              );
            })}
            </tbody>
          </Table>
        </Tab>
        <Tab
          eventKey="unrecognized"
          title={`Нераспознаные (${showCount(data.unrecognized)})`}
        >
          <Table striped hover>
            {renderHeader(true)}
            {renderStandartBody(data.unrecognized,true)}
          </Table>
        </Tab>
        {data.tableData && <Tab
          eventKey="toGoogle"
          title={`Для вставки`}
        >
          <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
            <input
              defaultChecked={showKrs}
              onChange={()=>setShowKrs(!showKrs)}
              type={"checkbox"}
            />{" "}
            Показать колонку "Крс"
          </Form.Label>
          {showKrs && <div className={'text-danger text-center'}>Копирование вместе с крс недоступно!Выключите показ крс</div>}
          <Table bordered className={showKrs ? 'user-select-none' : ''}>
            {showKrs &&
              <thead>
              <tr>
                <td>Выписан</td>
                <td>Начало</td>
                <td>Номер</td>
                <td>Пассажир</td>
                <td>Маршрут</td>
                <td>Крс</td>
                <td>Итого</td>
              </tr>
              </thead>}
            <tbody>
            {data.tableData.map((row,index)=>{
              return <tr key={index} style={{color:'black'}}>
                {row.map((c,i)=>{
                  return <td key={i}
                             className={i===0 ?'text-end':''}
                             style={i===5 ? {display: showKrs ? 'block' : 'none'}:{}}
                  >{c}</td>
                })}
              </tr>
            })}
            </tbody>


          </Table>
        </Tab>}

      </Tabs>
    </Row>

    </>
  );
};

export default YanaKayarRender;
const renderValue = (value) =>{
  if(typeof value !== 'object'){
    return <div className={'text-center'}>{numberFormat(value)}</div>
  }else{
    // return `${numberFormat(value.site)}/${numberFormat(value.kayar)}`
    return <div className={'d-flex justify-content-between'} style={{padding:'0 20px'}}>
      <div>{numberFormat(value.yana)}</div>
      <div>{numberFormat(value.kayar)}</div>
    </div>
  }
}


const renderHeader = (source) => {
  return <thead>
  <tr>
    <td>Номер</td>
    <td>Выписан</td>
    <td>Итого</td>
    {source && <td>Источник</td>}
  </tr>
  </thead>
}

const renderStandartBody = (array,source) =>{
  return <tbody style={{color:"black"}}>
  {array.map((u, index) => {
    const style={width: 850,color:"black"}
    const renderFirstCol = (! u.airline && !u.ticketNumber) ?
      <td style={style}>{u.description}</td> :
      <td style={style}>{u?.airline} {u.ticketNumber} {u.refund && ' B'}</td>

    return (
      <tr key={index}>
        {renderFirstCol}
        <td>{moment(u.issueDate).format('DD.MM.YYYY')}</td>
        <td>{numberFormat(u.total)}</td>
        {source && <td>{u.source}</td>}
      </tr>
    );
  })
  }
  <tr>
    <td colSpan={2}>
      <b>Итого</b>
    </td>
    <td>
      <b>
        {numberFormat(
          array
            .map(u => u.total)
            .reduce((a, b) => a + b, 0)
        )}
      </b>
    </td>
  </tr>
  </tbody>

}